import {
  array,
  arrayOf,
  bool,
  number,
  object,
  objectOf,
  oneOf,
  shape,
  string,
} from 'prop-types';

import { Priority } from '@/pages/TaskBasedWorkflow/constants';
import { actionShape } from '@/utils/transformFrontendAction';
import { flagHistoryShape } from '@/utils/transformFlagHistory';
import { hideForNowHistoryShape } from '@/utils/transformHideForNowHistory';
import { actionHelpShape } from '@/utils/transformFrontendCareGapAction';
import { addressShape } from '@/utils/transformFrontendAddress';

export const legacyNoteShape = shape({
  date: string,
  author: string,
  text: string,
});

export const programEnrollmentShape = shape({
  program: shape({
    name: string,
    id: number,
    description: string,
  }),
  enrolledAt: string,
});

// Have to do this because direct, top-level circular references aren't
// supported. Instead, dereference behind a function call. Note that the
// full signature of proptype validators isn't public, so we pass ...args
// as a way to signal that this function is a blind proxy for the proptype
// validation system.
function userInputTasksForPatientShape(...args) {
  // eslint-disable-next-line no-use-before-define
  return userInputTaskShape(...args);
}

export const patientShape = shape({
  id: number,
  memberNumber: string,
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  dob: string,
  contactInfoForDisplay: string,
  contactInfoNotes: string,
  gender: string,
  hccSupersedes: objectOf(arrayOf(number)),
  hccIsSupersededBy: objectOf(arrayOf(number)),
  suggestedActionHelpText: actionHelpShape,
  severityScore: number,
  pointOfCareSvusAfterPromo: string,
  qualityGap: number,
  codingGap: number,
  codingGapConfirmed: number,
  annualCareVisit: shape({
    state: string,
    last: string,
    scheduled: string,
  }),
  lastAcvVisit: string,
  annualCareReminderPostponedTo: string,
  medicareStatusCode: string,
  dualStatusCode: string,
  originalEntitlementStatusCode: string,
  tags: arrayOf(string),
  address: addressShape,
  provider: shape({
    id: number,
    name: string,
  }),
  plan: shape({
    id: number,
    name: string,
    annualCareVisitEligibility: string,
  }),
  attributedMgOffice: shape({
    id: number,
    name: string,
  }),
  medicalGroup: shape({
    id: number,
    name: string,
  }),
  userInputTasks: arrayOf(userInputTasksForPatientShape),
  rolledUpTbwPriority: oneOf([
    Priority.high,
    Priority.moderate,
    Priority.average,
  ]),
  userDrivenEnrollmentForms: objectOf(
    shape({
      title: string,
      fields: array,
    }),
  ),
  programEnrollments: arrayOf(programEnrollmentShape),
});

export const userInputTaskShape = shape({
  id: number,
  priority: oneOf([Priority.high, Priority.moderate, Priority.average]),
  action: actionShape,
  flagHistories: arrayOf(flagHistoryShape),
  isFlagged: bool,
  hiddenForNow: bool,
  hideForNowHistories: arrayOf(hideForNowHistoryShape),
  legacyNotes: arrayOf(legacyNoteShape),
  // Don't use patientShape here to avoid a circular reference
  patient: object,
  dischargeDate: string,
  facilityName: string,
  followUpVisits: string,
  outreachStatus: string,
  score: number,
});

export const patientExperienceRatingCountsShape = shape({
  1: number,
  2: number,
  3: number,
  4: number,
  5: number,
  NA: number,
});

export const patientExperienceDetailsDataShape = shape({
  id: string,
  label: string,
  shortLabel: string,
  totalVisitFollowups: number,
  visitFollowupsWithCompletedSurvey: number,
  avgPatientExperienceRating: number,
  patientExperienceRatingCounts: patientExperienceRatingCountsShape,
});

// Different statuses for a React-Query request:
export const reactQueryStatusValues = oneOf([
  'idle',
  'loading',
  'success',
  'error',
]);
