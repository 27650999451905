import useId from '@mui/utils/useId';
import { useController } from 'react-hook-form';
import { useMemo, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ThemeProvider } from '@mui/material';

import BaseSelect from '@/components/BaseSelect';
import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';
import useGetUserFilters from '@/hooks/useGetUserFilters';
import FormErrorMessage from '@/components/FormErrorMessage';
import transformOption, { getDefaultValue } from '@/utils/transformOption';
import themeV2 from '@/themeV2';

import DynamicFormLabel from '../DynamicFormLabel';

export default function DynamicFormOutsideProviderDropdown({ field }) {
  const { label, name } = field;
  const fieldId = useId();
  const { data, isLoading } = useGetUserFilters();
  const controller = useController({
    name,
    defaultValue: getDefaultValue(data?.providers),
    rules: {
      required: false,
    },
  });

  const { providers = [] } = data || {};

  const options = useMemo(() => providers.map(transformOption), [providers]);

  // Don't pass a value until the data is finished loading.
  // Otherwise, MUI will complain that the form's default value (if there is one) isn't an available option.
  const value = isLoading ? undefined : controller.field.value;
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [lastProviderValue, setLastProviderValue] = useState(
    controller.field.value,
  );

  const props = {};
  if (field.onChangeSelectedProviderId) {
    props.onChange = (e) => {
      field.onChangeSelectedProviderId(Number(e.target.value));
    };
  }

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
    if (event.target.checked) {
      setLastProviderValue(controller.field.value);
      controller.field.onChange(undefined);
    } else {
      controller.field.onChange(lastProviderValue);
    }
  };
  return (
    <>
      <DynamicFormLabel htmlFor={fieldId}>{label}</DynamicFormLabel>
      <BaseSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...controller.field}
        data-pendo-id={field.dataPendoId}
        fullWidth
        label={label}
        options={options}
        value={isCheckboxChecked ? undefined : value}
        disabled={isCheckboxChecked}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <ThemeProvider theme={themeV2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckboxChecked}
              onChange={handleCheckboxChange}
              name="outsideProvider"
            />
          }
          label="Completed by a provider outside this practice."
          sx={(theme) => ({ marginTop: theme.spacing(1) })}
        />
      </ThemeProvider>
      <FormErrorMessage errors={controller.formState.errors} name={name} />
    </>
  );
}

DynamicFormOutsideProviderDropdown.propTypes = {
  field: executionRequirementShape.isRequired,
};
