import { actionShape } from '@/utils/transformFrontendAction';
import useGetContextualInfo from '@/pages/TaskBasedWorkflow/hooks/useGetContextualInfo';
import FieldSkeleton from '@/pages/TaskBasedWorkflow/components/taskTableView/FieldSkeleton';

import { RX_PICKUP_SUBTYPES } from '../../constants';

import FieldBlank from './FieldBlank';

export default function PYMSRenewalDueDate({ action }) {
  const { data: contextualInfo, isLoading } = useGetContextualInfo(action);

  // Expected pickup date is not valid for rx pickup tasks.
  if (RX_PICKUP_SUBTYPES.includes(action.subtype)) {
    return <FieldBlank isLoading={isLoading} />;
  }

  return isLoading ? (
    <FieldSkeleton />
  ) : (
    <span style={{ whiteSpace: 'nowrap' }}>
      {contextualInfo.renewalDueDate}
    </span>
  );
}

PYMSRenewalDueDate.propTypes = {
  action: actionShape.isRequired,
};
