import PropTypes from 'prop-types';

import { addressShape } from '@/utils/transformFrontendAddress';
import { hccShape } from '@/utils/transformFrontendAction';

const planProviderOfficeShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

const diagnosisSubheadShape = PropTypes.shape({
  code: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  provider: PropTypes.string,
});

const diagnosisCodesShape = PropTypes.shape({
  historicCodes: PropTypes.arrayOf(PropTypes.string),
  commonCodes: PropTypes.arrayOf(PropTypes.string),
});

const diagnosisActionShape = PropTypes.shape({
  id: PropTypes.number,
  subtype: PropTypes.string,
  type: PropTypes.string,
  state: PropTypes.string,
  text: PropTypes.string,
  subhead: diagnosisSubheadShape,
  options: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ),
  codes: diagnosisCodesShape,
});

const clinicalEvidenceShape = PropTypes.shape({
  code: PropTypes.string,
  description: PropTypes.string,
});

const suspectedActionShape = PropTypes.shape({
  id: PropTypes.number,
  subtype: PropTypes.string,
  type: PropTypes.string,
  state: PropTypes.string,
  text: PropTypes.string,
  subhead: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ),
  clinicalEvidences: PropTypes.arrayOf(clinicalEvidenceShape),
});

const careGapActionShape = PropTypes.shape({
  id: PropTypes.number,
  subtype: PropTypes.string,
  type: PropTypes.string,
  state: PropTypes.string,
  text: PropTypes.string,
  subhead: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ),
  recentDate: PropTypes.string,
  recentProvider: PropTypes.string,
  recentAdtDate: PropTypes.string,
  recentAdtFacility: PropTypes.string,
});

const logoShape = PropTypes.shape({
  planLogoPosition: PropTypes.string,
  stellarLogoPosition: PropTypes.string,
  planLogoDataUrl: PropTypes.string,
});

const referralShape = PropTypes.shape({
  referralModuleSvuLower: PropTypes.string,
  referralModuleSvuUpper: PropTypes.string,
  referralModuleCategories: PropTypes.arrayOf(PropTypes.string),
});

const bannerShape = PropTypes.shape({
  header: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
});

const frequentEdNoteShape = PropTypes.shape({
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  text: PropTypes.string,
});

const frequentEdShape = PropTypes.shape({
  isNotSetup: PropTypes.bool,
  categories: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  keyFactors: PropTypes.string,
  clinicalPlan: PropTypes.string,
  notes: PropTypes.arrayOf(frequentEdNoteShape),
  remainder: PropTypes.number,
});

const visitChecklistShape = PropTypes.shape({
  checklist: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      subtext: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
});

const referralInputShape = PropTypes.shape({
  provider: PropTypes.string,
  address: addressShape,
});

const referralFormShape = PropTypes.shape({
  help: PropTypes.string,
  referralName: PropTypes.string,
  referralInputs: PropTypes.arrayOf(referralInputShape),
});

const patientInfoShape = PropTypes.shape({
  id: PropTypes.number,
  memberNumber: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  plan: planProviderOfficeShape,
  provider: planProviderOfficeShape,
  attributedMgOffice: planProviderOfficeShape,
  headline: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
});

const clinicalGuidelineShape = PropTypes.shape({
  id: PropTypes.number,
  guideline: PropTypes.string,
  summary: PropTypes.string,
  description: PropTypes.string,
  guidelineProvidedBy: PropTypes.string,
});

const appointmentShape = PropTypes.shape({
  id: PropTypes.number,
  patientId: PropTypes.number,
  datetimeOfService: PropTypes.string,
  serviceProvider: planProviderOfficeShape,
  serviceLocation: PropTypes.string,
});

const diagnosisShape = PropTypes.shape({
  id: PropTypes.number,
  claimId: PropTypes.number,
  diagnosisCode: PropTypes.string,
  codeType: PropTypes.string,
  hcc: hccShape,
  diagnosisDate: PropTypes.string,
  provider: planProviderOfficeShape,
  description: PropTypes.string,
});

const claimShape = PropTypes.shape({
  claimId: PropTypes.number,
  patientId: PropTypes.number,
  fromDate: PropTypes.string,
  thruDate: PropTypes.string,
  isEd: PropTypes.bool,
  likelyAvoidable: PropTypes.bool,
  facilityName: PropTypes.string,
  diagnosedBy: PropTypes.string,
  provider: planProviderOfficeShape,
  revenueCode: PropTypes.string,
  setting: PropTypes.string,
  diagnoses: PropTypes.arrayOf(diagnosisShape),
});

const groupClaimShape = PropTypes.shape({
  patientId: PropTypes.number,
  groupFromDate: PropTypes.string,
  groupThruDate: PropTypes.string,
  isEd: PropTypes.bool,
  likelyAvoidable: PropTypes.bool,
  facilityName: PropTypes.string,
  claimRows: PropTypes.arrayOf(claimShape),
  mostRecentDiagnoses: PropTypes.arrayOf(diagnosisShape),
});

const otherVisitShape = PropTypes.shape({
  claimId: PropTypes.number,
  patientId: PropTypes.number,
  fromDate: PropTypes.string,
  diagnoses: PropTypes.arrayOf(diagnosisShape),
});

const officeVisitShape = PropTypes.shape({
  mostRecentClaimId: PropTypes.number,
  patientId: PropTypes.number,
  fromDate: PropTypes.string,
  providerType: PropTypes.string,
  provider: planProviderOfficeShape,
  mostRecentDiagnoses: PropTypes.arrayOf(diagnosisShape),
  otherVisitsToThisProvider: PropTypes.arrayOf(otherVisitShape),
});

const evidenceShape = PropTypes.shape({
  inpatientErVisits: PropTypes.arrayOf(groupClaimShape),
  dxHistory: PropTypes.arrayOf(diagnosisShape),
  officeVisits: PropTypes.arrayOf(officeVisitShape),
});

const visitsAndDxHistoryShape = PropTypes.shape({
  isMedicalHistoryEnabled: PropTypes.bool,
  ppvCadence: PropTypes.string,
  evidence: evidenceShape,
  risks: PropTypes.arrayOf(PropTypes.string),
});

const adtEventShape = PropTypes.shape({
  dischargeDate: PropTypes.string,
  facility: PropTypes.string,
  diagnoses: PropTypes.string,
  daysSinceDischarge: PropTypes.number,
});

const summaryShape = PropTypes.shape({
  patientInfo: patientInfoShape,
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      diagnosisActionShape,
      suspectedActionShape,
      careGapActionShape,
    ]),
  ),
  logo: logoShape,
  referral: referralShape,
  banner: bannerShape,
  frequentEd: frequentEdShape,
  visitChecklist: visitChecklistShape,
  referralForms: PropTypes.arrayOf(referralFormShape),
  clinicalGuidelines: PropTypes.arrayOf(clinicalGuidelineShape),
  patientMedicalHistory: visitsAndDxHistoryShape,
  adtEvent: adtEventShape,
});

export {
  summaryShape,
  bannerShape,
  referralShape,
  logoShape,
  diagnosisActionShape,
  suspectedActionShape,
  careGapActionShape,
  frequentEdShape,
  frequentEdNoteShape,
  visitChecklistShape,
  referralFormShape,
  referralInputShape,
  patientInfoShape,
  appointmentShape,
  diagnosisShape,
  officeVisitShape,
  claimShape,
  groupClaimShape,
  otherVisitShape,
  evidenceShape,
  visitsAndDxHistoryShape,
  clinicalGuidelineShape,
};
