import PropTypes from 'prop-types';
import { useState } from 'react';
import { Alert, Typography } from '@mui/material';

import { useStepExecutionContext } from '@/pages/TaskBasedWorkflow/components/patientDetailView/stepExecutionPanel/StepExecutionContext';
import BaseFormModal from '@/components/BaseFormModal';
import DynamicFormProviderDropdown from '@/components/DynamicForm/fieldComponents/DynamicFormProviderDropdown';
import DynamicFormPhoneNumberInput from '@/components/DynamicForm/fieldComponents/DynamicFormPhoneNumberInput';
import useGetProviderOfficePhoneNumber from '@/pages/TaskBasedWorkflow/hooks/useGetProviderOfficePhoneNumber';

function outreachStartModalDynamicComponentMapper(field) {
  switch (field.type) {
    case 'provider':
      return DynamicFormProviderDropdown;
    case 'phoneInput':
      return DynamicFormPhoneNumberInput;
    default:
      return null;
  }
}

export default function OutreachStartModal({
  open,
  isSubmitting,
  onCancel,
  onSubmit,
  onClose,
  errorMessage,
}) {
  const { patient } = useStepExecutionContext();
  const [providerId, setProviderId] = useState(patient?.provider?.id);
  const { data } = useGetProviderOfficePhoneNumber(providerId);
  const providerOfficePhoneNumber = data?.data?.providerOfficePhoneNumber;
  const title = 'Start automated outreach';
  const formFields = [
    {
      name: 'patientPhoneNumber',
      label: 'Patient phone number',
      type: 'phoneInput',
      required: true,
      defaultValue: patient?.address?.phoneNumber || '',
    },
    {
      name: 'providerSelection',
      label: "Patient's Provider",
      type: 'provider',
      required: true,
      defaultValue: providerId,
      onChangeSelectedProviderId: setProviderId,
    },
    {
      name: 'officePhoneNumber',
      label: 'Callback number for your practice',
      type: 'phoneInput',
      required: true,
      defaultValue: providerOfficePhoneNumber || '',
    },
  ];
  return (
    <BaseFormModal
      open={open}
      isLoading={isSubmitting}
      title={title}
      alert={
        <>
          {errorMessage && (
            <Alert
              severity="error"
              sx={(theme) => ({
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                display: 'flex',
                alignItems: 'center',
              })}
            >
              {errorMessage}
            </Alert>
          )}
          <Typography
            sx={{
              color: 'text.secondary',
            }}
          >
            {patient?.firstName} {patient?.lastName}
          </Typography>
          <Typography
            sx={{
              marginTop: (theme) => theme.spacing(4),
            }}
          >
            Stellar will make 3 phone outreach attempts on behalf of your
            practice. Calls will be made between <b>9:00 AM and 11:00 AM</b> or
            between <b>2:00 PM and 4:00 PM</b> local time. Patients will
            experience these calls as coming directly from your practice.
          </Typography>
        </>
      }
      onClose={onClose}
      fields={formFields}
      onCancel={onCancel}
      footer={
        <Typography sx={{ fontWeight: 'bold' }}>
          You can discontinue automated outreach for this patient at any time by
          clicking “Stop automated outreach” in the Outreach Log.
        </Typography>
      }
      headerVariant="h2"
      modalSx={{ width: '638px' }}
      onSubmit={onSubmit}
      submitButtonLabel="Designate for outreach"
      fieldComponentMapperOverride={outreachStartModalDynamicComponentMapper}
      submitButtonPendoId="outreach-campaign-start-submit"
      cancelButtonPendoId="outreach-campaign-start-cancel"
    />
  );
}

OutreachStartModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

OutreachStartModal.defaultProps = {
  errorMessage: null,
};
