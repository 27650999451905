import { format, isValid, parse } from 'date-fns';

import transformFrontendBanner from '@/utils/transformFrontendBanner';
import {
  DISPLAY_DATE_FORMAT,
  ISO_DATE_FORMAT,
} from '@/pages/PatientSummary/utils/constants';
import camelCaseify from '@/pages/PatientSummary/utils/camelCaseify';
import { AnnualCareVisit, Patient, Plan } from '@/utils/types';

import transformFrontendAddress from './transformFrontendAddress';
// eslint-disable-next-line import/no-cycle
import transformFrontendUserInputTask from './transformFrontendUserInputTask';
import transformFrontendMedicalGroup from './transformFrontendMedicalGroup';

// @ts-expect-error -- shapes has been moved to types.ts so after all references are removed
// we can remove this too.
export { patientShape, programEnrollmentShape } from './shapes';

const STATE_COMPLETE = 'complete';
const STATE_PENDING = 'pending';
const STATE_POSTPONED = 'postponed';
const STATE_SCHEDULED = 'scheduled';
const USER = 'user';
const USER_CONFIRMED = 'user_confirmed';

// This reference date is unused but is a required parameter for the `parse()` function.
// See: https://date-fns.org/v2.25.0/docs/parse#syntax
const referenceDate = new Date();

export function formatDateOfBirth(input: string): string {
  const parsedDate = parse(input, ISO_DATE_FORMAT, referenceDate);
  return isValid(parsedDate) ? format(parsedDate, DISPLAY_DATE_FORMAT) : input;
}

/* eslint-disable
   @typescript-eslint/no-unsafe-member-access,
   @typescript-eslint/no-explicit-any,
   @typescript-eslint/no-unsafe-assignment,
   @typescript-eslint/no-unsafe-return
 */

export function getAnnualCareVisit(
  actions: Array<any>,
): AnnualCareVisit | Record<string, never> {
  // Get the most recent action that is of subtype 'annual_care_visit'
  // Currently we shouldn't ever get more than 1, but this is a bit of
  // future-proofing
  const acv = actions.reduce((memo, action) => {
    if (action.subtype === 'annual_care_visit' && memo) {
      return action.id > memo.id ? action : memo;
    }

    if (action.subtype === 'annual_care_visit') {
      return action;
    }

    return memo;
  }, null);

  if (acv === null) return {};

  const annualCareVisit = {} as AnnualCareVisit;

  if (
    acv.state === STATE_COMPLETE &&
    (acv.completed_by === USER || acv.completed_by === USER_CONFIRMED)
  ) {
    annualCareVisit.state = 'Complete';
  } else if (acv.state === STATE_PENDING || acv.state === STATE_POSTPONED) {
    annualCareVisit.state = 'Eligible';
  } else if (acv.state === STATE_SCHEDULED && acv.completed_by === USER) {
    annualCareVisit.state = 'Scheduled';
  }

  annualCareVisit.last = acv.most_recent_annual_care_visit_date;
  annualCareVisit.scheduled = acv.date_of_service;

  return annualCareVisit;
}

/**
 * Transform data that came from the `FrontendPatient` class.
 */
/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call */
export default function transformFrontendPatient(patient: any): Patient {
  return {
    id: patient.id,
    memberNumber: patient.member_number,
    firstName: patient.first_name,
    lastName: patient.last_name,
    dateOfBirth: formatDateOfBirth(patient.date_of_birth),
    contactInfoForDisplay: patient.contact_info_for_display,
    contactInfoNotes: patient.contact_info_notes,
    gender: patient.gender,
    hccSupersedes: patient.hcc_supersedes
      ? JSON.parse(patient.hcc_supersedes)
      : {},
    hccIsSupersededBy: patient.hcc_is_superseded_by
      ? JSON.parse(patient.hcc_is_superseded_by)
      : {},
    suggestedActionHelpText: patient.suggested_action_help_text?.show
      ? patient.suggested_action_help_text
      : null,
    severityScore: patient.severity_score,
    pointOfCareSvusAfterPromo: patient.point_of_care_svus_after_promo,
    qualityGap: patient.quality_gap,
    codingGap: patient.coding_gap,
    codingGapConfirmed: patient.coding_gap_confirmed,
    annualCareVisit: getAnnualCareVisit(patient.actions || []),
    lastAcvVisit: patient.last_acv_visit,
    annualCareReminderPostponedTo: patient.annual_care_reminder_postponed_to,
    medicareStatusCode: patient.medicare_status_code,
    dualStatusCode: patient.dual_status_code,
    originalEntitlementStatusCode: patient.original_entitlement_status_code,
    providerVisitsCount: patient.num_provider_visits,
    filesCount: patient.num_files,
    medicationsCount: patient.num_medications,
    banner: patient.banner ? transformFrontendBanner(patient.banner) : null,
    tags: patient.tags || [],
    headline: patient.headline || '',
    address: patient.address ? transformFrontendAddress(patient.address) : null,
    provider: patient.provider,
    medicalGroup: patient.medical_group
      ? transformFrontendMedicalGroup(patient.medical_group)
      : null,
    plan: camelCaseify(patient.plan) as Plan,
    attributedMgOffice: patient.attributed_mg_office,
    actions: patient.actions || [],
    appointments: patient.appointments || [],
    userInputTasks: patient.user_input_tasks
      ? patient.user_input_tasks.map(transformFrontendUserInputTask)
      : [],
    rolledUpTbwPriority: patient.rolled_up_tbw_priority,
    userDrivenEnrollmentForms: patient.user_driven_enrollment_forms
      ? patient.user_driven_enrollment_forms
      : {},
    patientNeed: patient.patient_need,
  };
}
