import transformFrontendPatientNotes, {
  APIPatientNote,
} from '@/utils/transformFrontendPatientNotes';
import { formatISODateIgnoreTimeZone } from '@/utils/formatDate';
import type { UserInputTask } from '@/utils/types';
import { Priority } from '@/pages/TaskBasedWorkflow/constants';

// @ts-expect-error -- js file
import { tbwTransformFrontendAction } from './transformFrontendAction';
import transformFlagHistory, { APIFlagHistory } from './transformFlagHistory';
import transformHideForNowHistory, {
  APIHideForNowHistory,
} from './transformHideForNowHistory';
// eslint-disable-next-line import/no-cycle
import transformFrontendPatient from './transformFrontendPatient';
// @ts-expect-error -- js file
export { userInputTaskShape, legacyNoteShape } from './shapes';

type APIUserInputTask = {
  action: object;
  flag_histories: Array<APIFlagHistory>;
  id: number;
  is_flagged: boolean;
  hidden_for_now: boolean;
  priority:
    | typeof Priority.high
    | typeof Priority.moderate
    | typeof Priority.average;
  hide_for_now_histories: Array<APIHideForNowHistory>;
  legacy_notes: Array<APIPatientNote>;
  patient: object | null;
  discharge_date: string;
  facility_name: string;
  score: number;
  outreach_status: string;
  scheduled_visit: string;
};

export default function transformFrontendUserInputTask(
  userInputTask: APIUserInputTask,
): UserInputTask {
  return {
    action: tbwTransformFrontendAction(userInputTask.action),
    flagHistories: transformFlagHistory
      ? userInputTask.flag_histories.map(transformFlagHistory)
      : [],
    id: userInputTask.id,
    isFlagged: userInputTask.is_flagged,
    priority: userInputTask.priority,
    hiddenForNow: userInputTask.hidden_for_now,
    hideForNowHistories: userInputTask.hide_for_now_histories
      ? userInputTask.hide_for_now_histories.map(transformHideForNowHistory)
      : [],
    legacyNotes: transformFrontendPatientNotes(userInputTask.legacy_notes),
    patient: userInputTask.patient
      ? transformFrontendPatient(userInputTask.patient)
      : null,
    dischargeDate: formatISODateIgnoreTimeZone(userInputTask.discharge_date),
    facilityName: userInputTask.facility_name,
    score: userInputTask.score,
    outreachStatus: userInputTask.outreach_status,
    scheduledVisit: formatISODateIgnoreTimeZone(userInputTask.scheduled_visit),
  };
}
