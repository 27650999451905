// @ts-expect-error - js module
import { actionShape } from '@/utils/transformFrontendAction';
import useGetContextualInfo from '@/pages/TaskBasedWorkflow/hooks/useGetContextualInfo';
// @ts-expect-error - js module
import FieldSkeleton from '@/pages/TaskBasedWorkflow/components/taskTableView/FieldSkeleton';
import { Action } from '@/utils/types';

import { RX_PICKUP_SUBTYPES } from '../../constants';

import FieldBlank from './FieldBlank';

export default function ExpectedPickupDate({ action }: { action: Action }) {
  const {
    data: contextualInfo,
    isSuccess,
    isLoading,
  } = useGetContextualInfo(action);

  // Expected pickup date is valid only for rx pickup tasks.
  if (!RX_PICKUP_SUBTYPES.includes(action.subtype)) {
    return <FieldBlank isLoading={isLoading} />;
  }

  return !isSuccess ? (
    <FieldSkeleton />
  ) : (
    <span style={{ whiteSpace: 'nowrap' }}>
      {contextualInfo.expectedPickupDate}
    </span>
  );
}

ExpectedPickupDate.propTypes = {
  action: actionShape.isRequired,
};
