import PropTypes from 'prop-types';

import { formatISODateIgnoreTimeZone } from '@/utils/formatDate';
import transformFrontendCareGapAction, {
  actionProgressShape,
  careGapActionShapeV2,
  stepHistoryShape,
  stepShape,
  transformFrontendActionProgress,
  transformStep,
  transformStepHistory,
} from '@/utils/transformFrontendCareGapAction';
import { transformLatestExternalCareGap } from '@/utils/transformLatestExternalCareGap';
import { transformExternalCareGapRecentActivity } from '@/utils/transformExternalCareGapRecentActivity';
import {
  ActionType,
  DIAGNOSIS_ACTION_SUBTYPE_RECAPTURE,
  DIAGNOSIS_ACTION_SUBTYPE_SUGGESTION,
} from '@/pages/PatientSummary/utils/constants';

export function transformHcc(hcc) {
  return {
    code: hcc.code,
    name: hcc.name,
  };
}

function transformDiagnosis(diagnosis) {
  return {
    sourceType: diagnosis.source_type,
    sourceName: diagnosis.source_name,
    date: diagnosis.date,
  };
}

export function transformClinicalEvidence(clinicalEvidence) {
  return {
    hcc: clinicalEvidence.hcc,
    description: clinicalEvidence.description,
    evidence: clinicalEvidence.evidence,
    assessedOn: clinicalEvidence.assessed_on,
    icd: clinicalEvidence.icd,
  };
}

export function transformPastDiagnosis(pastDiagnosis) {
  return {
    icd: pastDiagnosis.icd,
    description: pastDiagnosis.description,
    pastDiagnoses: pastDiagnosis.past_diagnoses
      ? pastDiagnosis.past_diagnoses.map(transformDiagnosis)
      : [],
  };
}

export function transformCommonCode(commonCode) {
  return {
    icd: commonCode.icd,
    description: commonCode.description,
    raf: commonCode.raf,
    pastDiagnoses: [],
  };
}

function transformFieldsToDisplay(fieldsToDisplay) {
  return fieldsToDisplay?.map((field) => ({
    label: field.label,
    type: field.type,
    value:
      field.type === 'Date'
        ? formatISODateIgnoreTimeZone(field.value)
        : field.value,
  }));
}

export function transformContextualInfo(contextualInfo) {
  if (!contextualInfo) {
    return null;
  }

  if (contextualInfo?.hasBeenTransformed) {
    return contextualInfo;
  }

  return {
    hasBeenTransformed: true,
    trigger: contextualInfo.trigger,
    triggerUrl: contextualInfo.trigger_url,
    adherenceDaysLeft: contextualInfo.adherence_days_left,
    adherenceStatus: contextualInfo.adherence_status,
    drugName: contextualInfo.drug_name,
    refillProjectedDate: formatISODateIgnoreTimeZone(
      contextualInfo.refill_projected_date,
    ),
    absoluteFailDate: formatISODateIgnoreTimeZone(
      contextualInfo.absolute_fail_date,
    ),
    renewalDueDate: formatISODateIgnoreTimeZone(
      contextualInfo.renewal_due_date,
    ),
    expectedPickupDate: formatISODateIgnoreTimeZone(
      contextualInfo.expected_pickup_date,
    ),
    medicationName: contextualInfo.medication_name,
    isOn90DayScript: contextualInfo.is_on_90_day_script,
    lastFill: formatISODateIgnoreTimeZone(contextualInfo.last_fill),
    lastPrescriber: contextualInfo.last_prescriber,
    pharmacyName: contextualInfo.pharmacy_name,
    prescriptionHistory: contextualInfo.prescription_history,
    priorityScore: contextualInfo.priority_score,
    actionName: contextualInfo.action_name,
    category: contextualInfo.category,
    parentDateOfService: formatISODateIgnoreTimeZone(
      contextualInfo.parent_date_of_service,
    ),
    mostRecentDateForDisplay: formatISODateIgnoreTimeZone(
      contextualInfo.most_recent_date_for_display,
    ),
    mostRecentProviderForDisplay:
      contextualInfo.most_recent_provider_name_for_display,
    dateLastOfficeVisit: formatISODateIgnoreTimeZone(
      contextualInfo.date_last_office_visit,
    ),
    dateLastEdVisit: formatISODateIgnoreTimeZone(
      contextualInfo.date_last_ed_visit,
    ),
    edVisitProfile: contextualInfo.ed_visit_profile,
    edVisitsLastTwelveMonths: contextualInfo.ed_visits_last_twelve_months,
    dateLastAdmission: formatISODateIgnoreTimeZone(
      contextualInfo.date_last_admission,
    ),
    admissionsLastTwelveMonths: contextualInfo.admissions_last_twelve_months,
    adtEventFacility: contextualInfo.adt_event_facility,
    adtEventDate: formatISODateIgnoreTimeZone(contextualInfo.adt_event_date),
    lengthOfStay: contextualInfo.length_of_stay,
    fieldsToDisplay: transformFieldsToDisplay(contextualInfo.fields_to_display),
    latestExternalCareGap: transformLatestExternalCareGap(
      contextualInfo.latest_external_gap_history,
    ),
    externalCareGapRecentActivity: transformExternalCareGapRecentActivity(
      contextualInfo.external_gap_recent_activity,
    ),
    adtEventDiagnosisCode: contextualInfo.adt_event_diagnosis_code,
    adtEventDiagnosisDescription:
      contextualInfo.adt_event_diagnosis_description,
    adtEventDiagnosisHccDescription:
      contextualInfo.adt_event_diagnosis_hcc_description,
    enrollmentDaysAgo: contextualInfo.enrollment_days_ago,
    helpText: contextualInfo.help_text,
    listViewHelpText: contextualInfo.list_view_help_text,
    bannerHelpText: contextualInfo.banner_help_text,
    original: contextualInfo,
    testType: contextualInfo.test_type,
    numOpenV2Actions: contextualInfo.num_open_v2_actions,
    mostRecentAnnualVisitDate: contextualInfo.most_recent_annual_visit_date,
    mostRecentOtherVisit: contextualInfo.most_recent_other_visit,
    nextAnnualVisitDate: contextualInfo.next_annual_visit_date,
    lastOutreachDate: contextualInfo.last_outreach_date,
  };
}

function transformV2Dto(actionV2Dto) {
  return {
    actionId: actionV2Dto.action_id,
    createdAt: formatISODateIgnoreTimeZone(actionV2Dto.created_at),
    description: actionV2Dto.description,
    type: actionV2Dto.type,
    subtype: actionV2Dto.subtype,
    expiresAt: formatISODateIgnoreTimeZone(actionV2Dto.expires_at),
    dueDate: formatISODateIgnoreTimeZone(actionV2Dto.due_date),
    completeBy: formatISODateIgnoreTimeZone(actionV2Dto.complete_by),
    patientId: actionV2Dto.patient_id,
    possibleSvusRemainingPrePromo:
      actionV2Dto.possible_svus_remaining_pre_promo,
    possibleSvusRemainingPostPromo:
      actionV2Dto.possible_svus_remaining_post_promo,
    availableNextSteps: actionV2Dto.available_next_steps.map(transformStep),
    preferredPath: actionV2Dto.preferred_path?.map(transformStep) || [],
    contextualInfo: transformContextualInfo(actionV2Dto.contextual_info),
    stepHistories: actionV2Dto.step_histories.map(transformStepHistory),
    dateOfService: formatISODateIgnoreTimeZone(actionV2Dto.date_of_service),
    migratedFromId: actionV2Dto.migrated_from_id,
    version: actionV2Dto.version,
    phoneNumberInput: actionV2Dto.phone_number_input,
    pcpVisitDateOfService: actionV2Dto.pcp_visit_date_of_service,
    pxRating: actionV2Dto.px_rating,
  };
}

function transformFrontendDiagnosisActionV2(actionV2Dto) {
  return {
    id: actionV2Dto.action_id,
    type: actionV2Dto.type,
    subtype: actionV2Dto.subtype,
    updatedAt: actionV2Dto.updated_at,
    mostRecentlyCompletedByName: actionV2Dto.most_recently_completed_by_name,
    mostRecentlyCompletedByDate: actionV2Dto.most_recently_completed_by_date,
    mostRecentProviderForDisplay:
      actionV2Dto.contextual_info?.most_recent_provider_name_for_display,
    date: actionV2Dto.contextual_info?.last_visit_date,
    promo: Boolean(actionV2Dto.possible_svus_remaining_post_promo),
    svusBeforePromo: Number(actionV2Dto.possible_svus_remaining_pre_promo),
    svusAfterPromo: Number(actionV2Dto.possible_svus_remaining_post_promo),
    hcc: actionV2Dto.contextual_info?.hcc
      ? transformHcc(actionV2Dto.contextual_info?.hcc)
      : null,
    pastDiagnoses: actionV2Dto.contextual_info?.diagnoses
      ? actionV2Dto.contextual_info?.diagnoses?.map(transformPastDiagnosis)
      : [],
    clinicalEvidence: actionV2Dto.contextual_info?.clinical_evidence
      ? actionV2Dto.contextual_info?.clinical_evidence?.map(
          transformClinicalEvidence,
        )
      : [],
    hasBeenReopened: actionV2Dto.contextual_info?.has_been_reopened,
    progress: actionV2Dto.step_histories?.map(transformStepHistory),
  };
}

function transformFrontendDiagnosisAction(action) {
  return {
    id: action.id,
    subtype: action.subtype,
    type: action.type,
    state: action.state,
    active: action.active,
    updatedAt: action.updated_at,
    expiresAt: action.expires_at,
    minDate: action.min_date,
    mostRecentlyCompletedByName: action.most_recently_completed_by_name,
    mostRecentlyCompletedByDate: action.most_recently_completed_by_date,
    mostRecentProviderForDisplay: action.most_recent_provider_name_for_display,
    mostRecentExternalCareGapSource:
      action.most_recent_external_care_gap_source,
    mostRecentExternalCareGapDate: action.most_recent_external_care_gap_date,
    dateOfService: action.date_of_service,
    date: action.date,
    promo: action.promo,
    svusBeforePromo: Number(action.svus_before_promo),
    svusAfterPromo: Number(action.svus_after_promo),
    // These attributes only exist in DiagnosisActions
    hcc: action.hcc ? transformHcc(action.hcc) : null,
    pastDiagnoses: action.diagnoses
      ? action.diagnoses.map(transformPastDiagnosis)
      : [],
    commonCodes: action.common_codes
      ? action.common_codes.map(transformCommonCode)
      : [],
    userAddedDiagnosisCodes: action.user_added_diagnosis_codes
      ? action.user_added_diagnosis_codes
      : [],
    clinicalEvidence: action.clinical_evidence
      ? action.clinical_evidence.map(transformClinicalEvidence)
      : [],
    hasBeenReopened: action.has_been_reopened,
    v2Dto: action.v2_dto
      ? transformFrontendDiagnosisActionV2(action.v2_dto)
      : null,
    progress: action.progress?.show
      ? transformFrontendActionProgress(action.progress)
      : null,
    supersededHccs: action.superseded_hccs
      ? action.superseded_hccs.map(transformHcc)
      : [],
  };
}

export function tbwTransformFrontendAction(action) {
  return {
    id: action.id,
    subtype: action.subtype,
    type: action.type,
    v2Dto: transformV2Dto(action.v2_dto),
  };
}

/**
 * Transform data that came from the `FrontendPatientAction` class.
 */
export default function transformFrontendAction(action) {
  if (
    action.type === ActionType.diagnosis &&
    (action.subtype === DIAGNOSIS_ACTION_SUBTYPE_RECAPTURE ||
      action.subtype === DIAGNOSIS_ACTION_SUBTYPE_SUGGESTION)
  ) {
    return transformFrontendDiagnosisAction(action);
  }
  return transformFrontendCareGapAction(action);
}

export const hccShape = PropTypes.shape({
  code: PropTypes.number,
  name: PropTypes.string,
});

export const diagnosisShape = PropTypes.shape({
  sourceType: PropTypes.string,
  sourceName: PropTypes.string,
  date: PropTypes.string,
});

const groupedDiagnosisShape = PropTypes.shape({
  icd: PropTypes.string,
  description: PropTypes.string,
  pastDiagnoses: PropTypes.arrayOf(diagnosisShape),
});

const commonCodeShape = PropTypes.shape({
  icd: PropTypes.string,
  description: PropTypes.string,
  raf: PropTypes.bool,
  pastDiagnoses: PropTypes.arrayOf(diagnosisShape),
});

const clinicalEvidenceShape = PropTypes.shape({
  hcc: PropTypes.number,
  description: PropTypes.string,
  evidence: PropTypes.string,
  assessedOn: PropTypes.string,
  icd: PropTypes.string,
});

const v2DtoShape = PropTypes.shape({
  actionId: PropTypes.number,
  description: PropTypes.string,
  type: PropTypes.string,
  subtype: PropTypes.string,
  patientId: PropTypes.number,
  possibleSvusRemainingPrePromo: PropTypes.number,
  possibleSvusRemainingPostPromo: PropTypes.number,
  availableNextSteps: PropTypes.arrayOf(stepShape),
  preferredPath: PropTypes.arrayOf(stepShape),
  contextualInfo: PropTypes.object,
  stepHistories: PropTypes.arrayOf(stepHistoryShape),
});

const commonDiagnosisShape = {
  id: PropTypes.number,
  type: PropTypes.string,
  subtype: PropTypes.string,
  updatedAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mostRecentlyCompletedByName: PropTypes.string,
  mostRecentlyCompletedByDate: PropTypes.string,
  mostRecentProviderForDisplay: PropTypes.string,
  mostRecentExternalCareGapSource: PropTypes.string,
  mostRecentExternalCareGapDate: PropTypes.string,
  date: PropTypes.string,
  dateOfService: PropTypes.string,
  promo: PropTypes.bool,
  svusBeforePromo: PropTypes.number,
  svusAfterPromo: PropTypes.number,
  hcc: hccShape,
  pastDiagnoses: PropTypes.arrayOf(groupedDiagnosisShape),
  commonCodes: PropTypes.arrayOf(commonCodeShape),
  clinicalEvidence: PropTypes.arrayOf(clinicalEvidenceShape),
  hasBeenReopened: PropTypes.bool,
  progress: actionProgressShape,
  supersededHccs: PropTypes.arrayOf(hccShape),
};

export const actionShape = PropTypes.shape({
  ...commonDiagnosisShape,
  state: PropTypes.string,
  v2Dto: PropTypes.oneOfType([
    v2DtoShape,
    PropTypes.shape(commonDiagnosisShape),
    careGapActionShapeV2,
  ]),
});
