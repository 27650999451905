import axios from 'axios';
import DataLoader from 'dataloader';
import { useQuery } from '@tanstack/react-query';
import { noop } from 'lodash';

// @ts-expect-error - js module
import { transformContextualInfo } from '@/utils/transformFrontendAction';
// @ts-expect-error - js module
import queryKeyFactory from '@/pages/TaskBasedWorkflow/queryKeyFactory';
import { Action, ContextualInfo } from '@/utils/types';

type ContextualInfoData = { id: number } & Record<string, unknown>;

type ResponseData = Array<ContextualInfoData>;

const contextualInfoLoader = new DataLoader(
  async (actionIds: ReadonlyArray<number>) => {
    const params = { action_ids: actionIds };
    const response = await axios.get<ResponseData>(
      '/api/tbw/task_actions_context',
      {
        params,
      },
    );
    return response.data;
  },
  {
    cache: false, // Allow react-query to handle the caching
  },
);

export default function useGetContextualInfo(
  action: Action,
  { onSuccess = noop } = {},
) {
  const params = { actionId: action.id };

  return useQuery<ContextualInfo>({
    queryKey: queryKeyFactory.taskActionsContext(params),
    queryFn: async () => {
      if (action?.v2Dto?.contextualInfo) {
        // Bypass the query if contextualInfo already exists.
        return action.v2Dto.contextualInfo;
      }
      const data = await contextualInfoLoader.load(action.id);
      return transformContextualInfo(data) as ContextualInfo;
    },
    initialData: (action?.v2Dto?.contextualInfo
      ? action.v2Dto.contextualInfo
      : undefined)!,
    onSuccess,
  });
}
