export const COL_FLEX_SIZES = {
  DX_HISTORY: {
    conditions: 3,
    diagnoses: 4,
    last_diagnosed: 1.5,
    assessed_this_year: 1.5,
    assessed_by: 1.5,
  },
  INPATIENT_ED_VISITS_SUMMARY: {
    date: 1.5,
    type: 1.5,
    likely_avoidable: 2,
    facility: 2,
    diagnoses: 5,
  },
  OFFICE_VISITS_SUMMARY: {
    last_visit_date: 1.5,
    provider: 2,
    type: 2,
    number_of_visits: 1,
    diagnoses: 5,
  },
};

export const DX_FLEX_VALUES = Object.values(COL_FLEX_SIZES.DX_HISTORY);
export const INPATIENT_ED_VISITS_FLEX_VALUES = Object.values(
  COL_FLEX_SIZES.INPATIENT_ED_VISITS_SUMMARY,
);
export const OFFICE_VISITS_FLEX_VALUES = Object.values(
  COL_FLEX_SIZES.OFFICE_VISITS_SUMMARY,
);
