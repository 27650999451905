import { executionRequirementShape } from '@/utils/transformFrontendCareGapAction';
import useFeatureFlag from '@/utils/useFeatureFlag';
import DynamicFormProviderDropdown from '@/components/DynamicForm/fieldComponents/DynamicFormProviderDropdown';
import DynamicFormOutsideProviderDropdown from '@/components/DynamicForm/fieldComponents/DynamicFormOutsideProviderDropdown';

export default function DynamicFormOutsideProviderDropdownWrapper({ field }) {
  const isFFOn = useFeatureFlag('enable_provider_outside_of_practice');
  if (isFFOn) {
    return <DynamicFormOutsideProviderDropdown field={field} />;
  }

  return <DynamicFormProviderDropdown field={field} />;
}

DynamicFormOutsideProviderDropdownWrapper.propTypes = {
  field: executionRequirementShape.isRequired,
};
