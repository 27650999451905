import { userInputTaskShape } from '@/utils/transformFrontendUserInputTask';
import { ReactComponent as ShActiveCalling } from '@/assets/icons/shActiveCalling.svg';
import { ReactComponent as ShFailedOutreach } from '@/assets/icons/shFailedOutreach.svg';
import { ReactComponent as ShSuccessfulOutreach } from '@/assets/icons/shSuccessfulOutreach.svg';

export default function OutreachStatus({ task }) {
  switch (task.outreachStatus) {
    case 'successful':
      return <ShSuccessfulOutreach />;
    case 'failed':
      return <ShFailedOutreach />;
    case 'active':
      return <ShActiveCalling />;
    default:
      return null;
  }
}

OutreachStatus.propTypes = {
  task: userInputTaskShape.isRequired,
};
