// @ts-expect-error - js module
import FieldSkeleton from './FieldSkeleton';

export default function FieldBlank({
  isLoading = false,
}: {
  isLoading?: boolean;
}) {
  return isLoading ? (
    <FieldSkeleton />
  ) : (
    <span style={{ marginLeft: '4px' }}>--</span>
  );
}
