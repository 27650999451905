import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { merge } from 'lodash';
import { format } from 'date-fns';
import { AxiosResponse } from 'axios';

// @ts-expect-error - js module
import BaseCheckbox from '@/components/BaseCheckbox';
// @ts-expect-error - js module
import BaseTextarea from '@/components/BaseTextarea';
// @ts-expect-error - js module
import BaseModal from '@/components/BaseModal';
// @ts-expect-error - js module
import BaseForm from '@/components/BaseForm';
import useBulkExecuteSteps, {
  BulkExecuteStepsPayload,
  BulkExecuteStepsResult,
} from '@/pages/TaskBasedWorkflow/hooks/useBulkExecuteSteps';
import { Action, Patient, UserInputTask } from '@/utils/types';
import findTask from '@/pages/TaskBasedWorkflow/utils/findTask';

type ExecutionInput = {
  score: number;
  completed_date: string;
  notes: string | undefined;
  pcp_visit_servicing_provider_id: number;
};

function buildBulkExecuteStepsPayload({
  action,
  completedDate,
  notes,
  servicingProviderId,
  score,
}: {
  action: Action;
  score: number;
  completedDate: Date;
  notes: string;
  servicingProviderId: number;
}) {
  return {
    [action.id]: {
      step_id: action.v2Dto.preferredPath[0]!.stepId,
      execution_input: {
        score,
        completed_date: format(completedDate, 'yyyy-MM-dd'),
        notes: notes || undefined,
        pcp_visit_servicing_provider_id: servicingProviderId,
      },
    },
  } as BulkExecuteStepsPayload<ExecutionInput>;
}

type Props = {
  patient: Patient;
  patientTasks: Array<UserInputTask>;
  action: Action;
  otherRxPickupActions: Array<Action>;
  submittedData?: {
    pcp_visit_servicing_provider_id: number;
    completed_date: Date;
    notes: string;
  };
  open: boolean;
  onSuccess: (data: AxiosResponse<BulkExecuteStepsResult>) => void;
};

export default function RxPickupBulkUpdateModal({
  patient,
  patientTasks,
  action,
  otherRxPickupActions,
  submittedData = undefined,
  open,
  onSuccess,
}: Props) {
  const [selectedActionIds, setSelectedActionIds] = useState<Array<number>>([]);
  const [notes, setNotes] = useState('');
  const { mutate, isLoading } = useBulkExecuteSteps({ onSuccess });

  if (!submittedData) return null;

  const { completed_date: completedDate } = submittedData;

  const handleSubmit = (includeOtherActions: boolean) => {
    const payload = buildBulkExecuteStepsPayload({
      score: findTask(action, patientTasks)!.score,
      action,
      completedDate,
      notes: submittedData.notes,
      servicingProviderId: patient.provider?.id,
    });

    if (!includeOtherActions) {
      mutate(payload);
    } else {
      const additionalPayloads = otherRxPickupActions
        .filter((x) => selectedActionIds.includes(x.id))
        .map((x) =>
          buildBulkExecuteStepsPayload({
            action: x,
            score: findTask(x, patientTasks)!.score,
            completedDate,
            notes,
            servicingProviderId: patient.provider?.id,
          }),
        );

      mutate(
        merge(
          payload,
          ...additionalPayloads,
        ) as BulkExecuteStepsPayload<ExecutionInput>,
      );
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, taskId: number) => {
    if (e.target.checked) {
      setSelectedActionIds((oldVal) => [...oldVal, taskId]);
    } else {
      setSelectedActionIds((oldVal) => oldVal.filter((id) => id !== taskId));
    }
  };

  const handleChangeNote = (e: ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  return (
    <BaseModal title="Confirmed" open={open} sx={{ maxWidth: '665px' }}>
      <p>
        Were any of these other late pickups also addressed with the patient on{' '}
        <Typography variant="bodylargebold">
          {format(completedDate, 'MM-dd-yyyy')}
        </Typography>
        ?
      </p>

      <BaseForm onSubmit={handleSubmit}>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <FormGroup>
            {otherRxPickupActions.map((otherAction) => (
              <BaseCheckbox
                key={otherAction.id}
                label={otherAction.v2Dto.description}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, otherAction.id)
                }
              />
            ))}
          </FormGroup>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <FormLabel>Note (optional)</FormLabel>
          <BaseTextarea onChange={handleChangeNote} fullWidth />
        </FormControl>

        <Stack spacing={2} direction="row" mt={2} justifyContent="flex-end">
          <Button
            data-pendo-id="rx-pickup-bulk-update-proceed-without-closing"
            disabled={isLoading}
            onClick={() => handleSubmit(false)}
            variant="secondary"
          >
            Proceed without closing
          </Button>
          <Button
            data-pendo-id="rx-pickup-bulk-update-close-selected-actions"
            onClick={() => handleSubmit(true)}
            disabled={isLoading ? true : selectedActionIds.length === 0}
          >
            Close selected action(s)
          </Button>
        </Stack>
      </BaseForm>
    </BaseModal>
  );
}
